// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Import Custom SB Admin 2 Mixins and Components
@import "global.scss";
@import "utilities.scss";

// Custom Components
@import "dropdowns.scss";
@import "navs.scss";
@import "buttons.scss";
@import "cards.scss";
//@import "charts.scss";
@import "login.scss";
@import "error.scss";
@import "footer.scss";

// Select 2
@import "select2";
@import '~flatpickr/dist/flatpickr.css';


.select2-container .select2-selection--single{
    border: 1px solid #cfdadd;
    border-radius: 2px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    color: #555;
    height:37px;
    padding: 5px;
}

.msgAlert{
    min-height: 100px;
    position: absolute;
    top: 50px;
    right: 20px;
    width: 300px;
    z-index: 100;
}

ul.checktree ul{
    padding-inline-start: 40px !important;
    list-style: none;
}
ul.checktree li:before {
    height: 1em;
    width: 12px;
    border-bottom: 1px dashed;
    content: "";
    display: inline-block;
    top: -0.3em;
}

ul.checktree li { border-left: 1px dashed; padding-bottom: 5px }

ul.checktree li:last-child:before { border-left: 1px dashed; }

ul.checktree li:last-child { border-left: none; }
ul.checktree label{
    margin-left: 10px;
}
ul.checktree{
    list-style: none;
    padding: 0;
}

.c,.c a{
    background: #BFF0DE !important;
    color: darkgreen;
}

.d,.d a{
    background: #f8ccc8 !important;
    color: red;
    font-weight: bold;
}

.e,.e a{
    background: #CDD8F6 !important;
    color: #2E59D9;
    font-weight: bold;
}

.w,.w a{
    background: #FBF7E3 !important;
    color: #856404;
}

.c .btn,.d .btn,.e .btn,.w .btn{
    color: #fff !important;
}
.c .btn-primary,.d .btn-primary,.e .btn-primary,.w .btn-primary{
    background-color: #4e73df !important;
}
.c .btn-danger,.d .btn-danger,.e .btn-danger,.w .btn-danger{
    background-color: #e02d1b !important;
}
.c .btn-success,.d .btn-success,.e .btn-success,.w .btn-success{
    background-color: #1cc88a !important;
}
.c .btn-dark,.d .btn-dark,.e .btn-dark,.w .btn-dark{
    background-color: #222 !important;
}

#page-top2 #accordionSidebar{
    background: #121212;
}

#page-top2 #accordionSidebar a{
    color: #fff;
    font-weight: bold;
}

#page-top2 #accordionSidebar a.collapse-item{
    color: #000;
}

#page-top1 #accordionSidebar{
    background: #344267;
}

#page-top1 #accordionSidebar a{
    color: #fff;
}

#page-top1 #accordionSidebar a.collapse-item{
    color: #344267;
}

.form-control{
    -moz-box-shadow:    inset 0 0 7px #ccc;
    -webkit-box-shadow: inset 0 0 7px #ccc;
    box-shadow:         inset 0 0 7px #ccc;
}

@keyframes spin {
    to { transform: rotate(360deg);}
}
.spinner {
    border: 8px solid rgba(0, 0, 0, 0.1);
    border-left-color: #22a6b3;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    animation: spin 1.2s linear infinite;
    margin: 300px auto 50px;
}
#loading{
    top: 0;
    height: 100vh;
    width: 2000px;
    margin-left: -240px;
    background: rgba(0,0,0,0.5);
    position: fixed;
    z-index: 100000;
}
